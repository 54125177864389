import { ImageOrFile } from "types/attheminute";
import NextImage from "next/legacy/image";
import { formatPrice } from "utils/storeHelpers";
import {
  useCartContext,
  useCartMetaContext,
  useUpdateCartQuantityContext,
} from "components/hocs/CartProvider";

import * as Styled from "./CartContent.styled";
import { Close } from "../Icons";

export default function CartContent() {
  const cart = useCartContext();
  const updateCartItemQuantity = useUpdateCartQuantityContext();
  const { isFetchingPrices } = useCartMetaContext();

  return (
    <>
      <Styled.Table>
        <Styled.TBody>
          {cart.map((item) => (
            <Styled.TR key={item.id}>
              <Styled.ProductImageTD>
                <NextImage
                  src={item.image?.url || "images/placeholder.png"}
                  alt={item.image?.alt || ""}
                  height={64}
                  width={64}
                />
              </Styled.ProductImageTD>
              <Styled.ProductDescriptionTD>
                <Styled.ProductName href={`/${item.fullSlug}`}>
                  {item.name}
                </Styled.ProductName>
                <Styled.Price>
                  {isFetchingPrices ? "Loading..." : null}
                  {!isFetchingPrices && item.price ? (
                    <strong>
                      {formatPrice(item.price, { alwaysShowDecimal: true })}
                    </strong>
                  ) : null}
                  {!isFetchingPrices && !item.price
                    ? "Price unknown - cannot checkout this item"
                    : null}
                </Styled.Price>

                <Styled.RemoveButton
                  aria-label="Delete Item"
                  onClick={() => updateCartItemQuantity(item.id, 0)}
                >
                  <Close />
                  <span>Remove</span>
                </Styled.RemoveButton>
              </Styled.ProductDescriptionTD>
              {/* <Styled.QuantityTD>
                <Styled.QuantityInput
                  initialValue={item.quantity}
                  onChange={(value) => updateCartItemQuantity(item.id, value)}
                  min={1}
                />
              </Styled.QuantityTD> */}
            </Styled.TR>
          ))}
        </Styled.TBody>
      </Styled.Table>
    </>
  );
}

import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import { sFont, mFont, fontFamilyBrand, fontFamilyLabel } from "styles/typography";
import Button from "components/ui/Button";
import NumberInput from "components/ui/NumberInput";
import Link from "components/ui/Link";
import { colourNames } from "styles/colours";
import spacing from "styles/spacing";

export const CheckoutButton = styled(Button)`
  width: 100%;
  align-self: center;
  max-width: 23.75rem;

  > div {
    width: 100%;
  }

  ${screenSize.minTablet`
    width: auto;
  `}
`;

export const RemoveButton = styled.button`
  font-family: ${fontFamilyLabel};
  margin-top: ${spacing.md};
  padding: 0;
  
  border: none;
  background: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: ${spacing.xxs};
  }

  > span {
    padding-top: 0.125rem;
  }

  &:hover {
    color: var(--color-coolAccent);
  }
`;

export const Table = styled.table`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;

  ${screenSize.minTablet`
    max-width: 42.5rem;
  `}
`;

export const TR = styled.tr`
  display: flex;
  margin-bottom: ${spacing.sm};
`;

export const TBody = styled.tbody`
  > tr {
    display: flex;
    align-items: flex-start;
  }
`;

const baseTH = styled.th`
  font-size: ${mFont};
`;

const productCol = `
  display: flex;
  align-items: center;
  flex: 1;
`;
export const ProductDescriptionTD = styled.td`
  ${productCol}
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${spacing.md};

  ${screenSize.mobileOnly`
    margin-right: 0;
  `};
`;
export const ProductTH = styled(baseTH)`
  ${productCol}
`;

export const ProductImageTD = styled.td`
  display: flex;
  align-items: center;
  flex: 0 0 5rem;

  > span {
    border-radius: 8px;
  }
`;

const quantityCol = `
  flex-basis: 2.5rem;
  text-align: center;
`;
export const QuantityTD = styled.td`
  ${quantityCol}
`;
export const QuantityTH = styled(baseTH)`
  ${quantityCol}
`;

export const QuantityInput = styled(NumberInput)`
  width: 2.5rem;
  font-family: ${fontFamilyBrand};
`;

const priceCol = `
  flex-basis: 3.75rem;
  text-align: center;
`;
export const PriceTD = styled.td`
  ${priceCol}
`;
export const PriceTH = styled(baseTH)`
  ${priceCol}
`;

export const ProductName = styled(Link)`
  font-size: ${mFont};
  font-family: ${fontFamilyBrand};
  margin-bottom: ${spacing.xxs};
`;

export const Price = styled.div`
  font-size: ${mFont};
  color: var(--color-${colourNames.TEXT});
`;

import styled from "styled-components";
import Button from "components/ui/Button";
import Input from "components/ui/formElements/Input";

const DefaultIncrementDecrement = styled(Button)`
  div {
    text-align: center;
    padding: 0.625rem;
    height: 100%;
    border: 1px solid var(--color-inputOutline);
  }
`;

export const Decrement = styled(DefaultIncrementDecrement)`
  div {
    border-radius: 4px 0px 0px 4px;
    border-right: none;
  }
`;

export const Increment = styled(DefaultIncrementDecrement)`
  div {
    border-radius: 0px 4px 4px 0px;
    border-left: none;
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
`;

export const NumberInput = styled(Input)`
  border-radius: 0;
  margin-right: 0;
  padding: 0;
  text-align: center;
  min-width: 2rem;
`;

import { Combobox, ComboboxInputProps } from "@headlessui/react";
import { breadcrumbFontStyles } from "components/ui/Breadcrumbs";
import Button from "components/ui/Button";
import {
  minDesktopXPadding,
  minTabletXPadding,
  mobileXPadding,
} from "components/ui/Layout";
import DefaultInput from "components/ui/formElements/Input";
import {
  InputStyleProps,
  inputStyles,
} from "components/ui/formElements/Input/Input.styled";
import { any } from "prop-types";
import { ComponentProps } from "react";
import styled from "styled-components";
import { colourNames } from "styles/colours";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import { mediumShadow } from "styles/tokens";
import { fontFamilyBrand, fontFamilyRegular, mFont, smallTextStyles } from "styles/typography";

export const IconInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Icon = styled.div`
  position: absolute;
  left: ${spacing.sm};
  color: var(--color-${colourNames.LABEL});
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  jusify-content: center;
`;

export const SearchButton = styled(Button)`
  flex: 0 0 auto;
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;

  > div {
    height: 100%;
    border-radius: 0px 4px 4px 0;
  }
`;

interface SiteSearchProps {
  $open: boolean;
}

export const SiteSearch = styled.div<SiteSearchProps>`
  position: relative;
  min-width: 0;
  transition: all ease 150ms;
  max-width: 28.125rem;
  width: 100%;

  ${(props) =>
    props.$open
      ? `
    transition: all ease 300ms;
    width: calc(100vw - calc(2 * ${mobileXPadding}));
    z-index: 100020;
`
      : ""}

  ${screenSize.minTablet`
    ${(props: SiteSearchProps) =>
      props.$open
        ? `
    width: calc(100vw - calc(2 * ${minTabletXPadding}));
    z-index: 100020;
  `
        : ""}
  `}

  ${screenSize.minDesktop`
    ${(props: SiteSearchProps) =>
      props.$open
        ? `
    width: calc(100vw - calc(2 * ${minDesktopXPadding}));
    z-index: 100020;
  `
        : ""}
  `}
`;

export const FullPageOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color-${colourNames.MODAL_OVERLAY});
`;

export const ComboboxInput = styled(Combobox.Input)<
  ComboboxInputProps<"input", any> & InputStyleProps & { $open?: boolean }
>`
  ${inputStyles}

  padding-left: ${spacing.lg};

  ${(props) =>
    props.$open ? `border-radius: 6px 6px 0px 0px;` : `border-radius: 6px;`}

  && {
    width: 100%;
  }
`;

export const ComboboxOptions = styled(Combobox.Options)`
  flex: 1;
  list-style: none;
  margin: 0;
  padding: ${spacing.sm};
  max-width: 100%;
`;

export const ComoboboxOption = styled(Combobox.Option)``;

export const OptionWrapper = styled.div<{ $active: boolean }>`
  min-height: 40px;
  display: flex;
  align-items: center;

  padding: 0 0.625rem;
  border-radius: 4px;
  cursor: pointer;

  ${smallTextStyles}

  ${(props) =>
    props.$active
      ? `background: var(--color-${colourNames.BACKGROUND_HOVER});`
      : ""}
`;

export const GroupHeading = styled.div`
  font-family: ${fontFamilyRegular};
  font-weight: 700;
  color: var(--color-label);
  font-size: ${mFont};
  margin-bottom: ${spacing.md};
  margin-top: ${spacing.md};
  padding: 0 0.625rem;
  position: relative;
`;

export const SiteSearchFlexWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const DropdownWrapper = styled.div<{ $open: boolean }>`
  display: flex;
  background: var(--color-${colourNames.BACKGROUND});
  flex 1;
  position: absolute;
  box-shadow: ${mediumShadow};
  max-height: calc(100vh - ${spacing.xxxl});
  overflow-y: scroll;

  border: 1px solid var(--color-inputOutline);
  border-top: none;
  width: 100%;

  ${(props) => (props.$open ? "" : "border: none;")}
`;

export const PreviewWindow = styled.div`
  flex: 1;
`;

import { SearchItem } from "components/organisms/SiteSearch/util";
import { matchSorter } from "match-sorter";
import searchJson from "../../public/files/search.json";

export default function useSiteSearch(
  query: string | null | undefined
): SearchItem[] {
  const searchItems = searchJson as SearchItem[];

  const allSearchResults = !!query
    ? matchSorter(searchItems, query, { keys: ["title"] })
    : [];

  return allSearchResults;
}

import * as React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import styled from "styled-components";
import HTMLButton from "components/ui/ActionLink/Button";
import spacing from "styles/spacing";
import { mFont, sFont } from "styles/typography";

import CtaButton, { CtaButtonProps } from "../CtaButton";

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    CtaButtonProps {
  icon?: React.ReactNode;
  loading?: boolean;
}

const StyledCtaButton = styled(CtaButton)<{ $iconOnly?: boolean }>`
  width: 100%;
  font-weight: 500;

  ${(props) => (props.$iconOnly ? `padding: ${spacing.xs};` : null)}

  > svg {
    font-size: ${mFont};
    ${(props) => !props.$iconOnly && `margin-right: ${spacing.xs};`}
  }

`;

export default function Button(props: ButtonProps) {
  const {
    icon,
    colourName,
    appearance = "solid",
    disabled,
    loading,
    children,
    ...rest
  } = props;

  return (
    <HTMLButton {...rest}>
      <StyledCtaButton
        colourName={colourName}
        appearance={appearance}
        disabled={disabled}
        $iconOnly={!!icon && !children}
      >
        {loading ? (
          <CircularProgress size={sFont} style={{ color: `var(--color-contrast-${colourName}` }} />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </StyledCtaButton>
    </HTMLButton>
  );
}

import axios from "axios";
import getConfig from "../config";

const config = getConfig();

const apiHostname = config.api.hostname;
const EMAIL_SUBSCRIBE_BASE_PATH = "atm-api/v1/event";

// eslint-disable-next-line import/prefer-default-export
export async function trackEvent(
  type:
    | "mouse-hover-preview-image"
    | "download-worksheet"
    | "search-type"
    | "search-select"
    | "search-execute",
  payload: any,
  clientTimestamp: number
) {
  if (window.localStorage.getItem("atm_tracking") === "no-track") {
    return;
  }

  return axios.post(`${apiHostname}/${EMAIL_SUBSCRIBE_BASE_PATH}`, {
    type,
    payload,
    clientTimestamp,
  });
}

import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import * as LayoutStyled from "../../../pages/BlogPage/BlogPage.styled";

export const DesktopNav = styled(LayoutStyled.Main)`
  display: none;

  ${screenSize.minTablet`
    display: grid;
    width: 100%;
  `}
`;

export const LeftContent = styled.div`
  grid-column: span 1;
`;

export const RightContent = styled.div`
  grid-column: span 3;

  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1.875rem;
`;

export const SiteSearchWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const MenuRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import useSiteSearch from "hooks/useSiteSearch";
import { useRouter } from "next/router";
import { useState, useEffect, useCallback } from "react";
import SearchResult from "./SearchResult";
import * as Styled from "./SiteSearch.styled";
import { SearchItem, isSearchItem } from "./util";
import { trackEvent } from "api/event";
import debounce from "lodash/debounce";
const SEARCH_FOR_QUERY_KEY = "!!!search-for!!!";

export const SiteSearchFlexWrapper = Styled.SiteSearchFlexWrapper;

export default function SiteSearch() {
  const [query, setQuery] = useState("");
  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState<
    SearchItem | null | typeof SEARCH_FOR_QUERY_KEY
  >(null);

  const allSearchResults = useSiteSearch(query);

  const teachingResourceMatches = allSearchResults
    .filter((searchResult) => searchResult.component === "teachingResource")
    .slice(0, 4);

  const blogArticleMatches = allSearchResults
    .filter((searchResult) => searchResult.component === "articlePage")
    .slice(0, 4);

  const showEmptyState =
    (!allSearchResults || allSearchResults.length === 0) && !query;

  const debouncedTrackTyping = useCallback(
    debounce((query: string) => {
      const savedQuery = query.trim();
      savedQuery &&
        trackEvent(
          "search-type",
          {
            query: savedQuery,
          },
          Date.now()
        );
    }, 500),
    []
  );

  useEffect(() => {
    debouncedTrackTyping(query);
  }, [debouncedTrackTyping, query]);

  const onChange = (value: SearchItem | null | typeof SEARCH_FOR_QUERY_KEY) => {
    setSelectedItem(value);

    // Trigger search page
    if (value === SEARCH_FOR_QUERY_KEY) {
      router.push(`/teaching/search?q=${query}`);
      trackEvent(
        "search-execute",
        {
          query,
        },
        Date.now()
      );
      return;
    }

    // Go to the item
    if (isSearchItem(value)) {
      trackEvent(
        "search-select",
        {
          id: value.id,
          slug: value.slug,
          query,
        },
        Date.now()
      );
      router.push(value.slug);
      return;
    }
  };

  return (
    <>
      <Combobox value={selectedItem} onChange={onChange}>
        {({ open }) => {
          return (
            <Styled.SiteSearch $open={open}>
              {open && <Styled.FullPageOverlay />}
              <Combobox.Button as="div" style={{ width: "100%" }}>
                <Styled.IconInputWrapper>
                  <Styled.Icon>
                    <MagnifyingGlassIcon />
                  </Styled.Icon>
                  <Styled.ComboboxInput
                    $open={open}
                    displayValue={(item) => (item ? item.name : null)}
                    placeholder="Search for products and blogs..."
                    onChange={(event) => {
                      setQuery(event.target.value);
                    }}
                  />
                </Styled.IconInputWrapper>
              </Combobox.Button>

              {/* SearchOptions */}
              {showEmptyState ? null : (
                <Styled.DropdownWrapper $open={open}>
                  <Styled.ComboboxOptions>
                    {query ? (
                      <Styled.ComoboboxOption
                        key={"search-for"}
                        value={SEARCH_FOR_QUERY_KEY}
                      >
                        {({ active }) => (
                          <Styled.OptionWrapper $active={active}>
                            {`Search for:  ${query}`}
                          </Styled.OptionWrapper>
                        )}
                      </Styled.ComoboboxOption>
                    ) : null}
                    {teachingResourceMatches &&
                    teachingResourceMatches.length > 0 ? (
                      <Styled.GroupHeading>Shop</Styled.GroupHeading>
                    ) : null}
                    {teachingResourceMatches &&
                    teachingResourceMatches.length > 0
                      ? teachingResourceMatches.map((searchResult) => {
                          return (
                            <Styled.ComoboboxOption
                              key={searchResult.id}
                              value={searchResult}
                            >
                              {({ active }) => (
                                <SearchResult
                                  fullSlug={searchResult.slug}
                                  description={searchResult.description}
                                  heading={searchResult.title}
                                  image={searchResult.previewImage}
                                  imageAlt={searchResult.previewImageAlt}
                                  active={active}
                                />
                              )}
                            </Styled.ComoboboxOption>
                          );
                        })
                      : null}

                    {blogArticleMatches && blogArticleMatches.length > 0 ? (
                      <Styled.GroupHeading>Blog articles</Styled.GroupHeading>
                    ) : null}
                    {blogArticleMatches && blogArticleMatches.length > 0
                      ? blogArticleMatches.map((searchResult) => {
                          return (
                            <Styled.ComoboboxOption
                              key={searchResult.id}
                              value={searchResult}
                            >
                              {({ active }) => (
                                <SearchResult
                                  style={{ flexDirection: "row-reverse" }}
                                  active={active}
                                  fullSlug={searchResult.slug}
                                  description={searchResult.description}
                                  heading={searchResult.title}
                                  image={searchResult.previewImage}
                                  imageAlt={searchResult.previewImageAlt}
                                />
                              )}
                            </Styled.ComoboboxOption>
                          );
                        })
                      : null}
                  </Styled.ComboboxOptions>
                </Styled.DropdownWrapper>
              )}
            </Styled.SiteSearch>
          );
        }}
      </Combobox>
    </>
  );
}

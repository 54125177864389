import * as React from "react";
import * as Styled from "./NumberInput.styled";

interface NumberInputProps {
  initialValue?: number;
  onChange?: (value: number) => void;
  className?: string;
  min?: number;
}

export default function NumberInput(props: NumberInputProps) {
  const { initialValue, onChange, className, min } = props;
  const [value, setValue] = React.useState(initialValue || 0);

  const setValueInternal = (newValue: number) => {
    if (min && newValue < min) {
      return;
    }
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const Wrapper = true ? Styled.NumberWrapper : React.Fragment;

  return (
    <Wrapper>
      <Styled.Decrement
        appearance="square"
        colourName="background"
        onClick={() => setValueInternal(value - 1)}
      >
        -
      </Styled.Decrement>
      <Styled.NumberInput
        id="number-input"
        tabIndex={-1}
        type="number"
        inputMode="numeric"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValueInternal(parseInt(e.target.value))}
        className={className}
        min={min}
      />
      <Styled.Increment
        appearance="square"
        colourName="background"
        onClick={() => setValueInternal(value + 1)}
      >
        +
      </Styled.Increment>
    </Wrapper>
  );
}

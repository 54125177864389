import * as React from "react";
import * as Styled from "./Input.styled";

const Input = React.forwardRef(
  (props: React.InputHTMLAttributes<HTMLInputElement>, ref: any) => (
    <Styled.Input ref={ref} {...props} />
  )
);

Input.displayName = "Input";

export default Input;

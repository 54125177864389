import styled from 'styled-components';
import { lightShadow } from 'styles/tokens';

export const Card = styled.div`
  display: flex;
  border-radius: 4px;
  box-shadow: ${lightShadow};
  background: var(--color-background);

  > * {
    flex: 1;
  }
`;
import { storyblokLoader } from "utils/storyblok";
import * as Styled from "./SearchResult.styled";
import { colourNames } from "styles/colours";

interface SearchResultProps {
  image?: string;
  imageAlt?: string;
  heading?: string;
  description?: string;
  hideDescription?: boolean;
  fullSlug: string;
  style?: React.CSSProperties;
  active?: boolean;
}

export default function SearchResult(props: SearchResultProps) {
  const {
    heading,
    description,
    hideDescription = false,
    image,
    imageAlt,
    active,
    style = {},
  } = props;

  return (
    <Styled.Wrapper $active={active} style={style}>
      <Styled.ImageWrapper>
        <Styled.PreviewImage
          loader={storyblokLoader}
          src={image || "images/placeholder.png"}
          alt={imageAlt}
          layout="fill"
        />
      </Styled.ImageWrapper>
      <Styled.ContentPreview>
        <Styled.Title noMarginTop>{heading}</Styled.Title>
        <Styled.Description
          hide={hideDescription}
          $backgroundColorName={
            active ? colourNames.BACKGROUND_HOVER : colourNames.BACKGROUND
          }
        >
          {description}
        </Styled.Description>
      </Styled.ContentPreview>
    </Styled.Wrapper>
  );
}

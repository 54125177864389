interface BaseSearchItem {
  id: string;
  title: string;
  slug: string;
  description?: string;
  previewImage?: string;
  previewImageAlt?: string;
}

interface TeachingResourceSearchItem extends BaseSearchItem {
  component: "teachingResource";
}

interface ArticleSearchItem extends BaseSearchItem {
  component: "articlePage";
}

export type SearchItem = TeachingResourceSearchItem | ArticleSearchItem;

export function isSearchItem(item: unknown): item is SearchItem {
  return !!(item as SearchItem)?.component;
}

export function isTeachingResourceSearchItem(
  item: unknown
): item is TeachingResourceSearchItem {
  return (item as TeachingResourceSearchItem)?.component === "teachingResource";
}

export function isArticleSearchItem(item: unknown): item is ArticleSearchItem {
  return (item as ArticleSearchItem)?.component === "articlePage";
}
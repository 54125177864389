import * as Styled from './Card.styled';

interface CardProps {
  className?: string,
  children?: React.ReactNode,
  style?: React.CSSProperties;
}

export default function Card(props: CardProps) {
  const { className, children, style={} } = props;

  return <Styled.Card className={className} style={style}>{children}</Styled.Card>;
};


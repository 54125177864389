import Badge from "@mui/material/Badge";
import {
  useCartContext,
  useCartMetaContext,
} from "components/hocs/CartProvider";
import { ThemeContext } from "components/hocs/ThemeProvider";
import DarkModeToggle from "components/ui/DarkModeToggle";
import useToggle from "hooks/useToggle";
import NewNextImage from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import styled from "styled-components";
import { fontFamilyRegular, xsFont } from "styles/typography";
import { numberOfCartItems } from "utils/storeHelpers";
import LogoLight from "../../../../../public/images/atm_logo_teaching.png";
import LogoDark from "../../../../../public/images/atm_logo_teaching_dark.png";
import Button from "../../Button";
import CartModal from "../../Cart/CartModal";
import { ShoppingCart } from "../../Icons";
import Burger from "../Burger";
import MobileMenu from "../MobileMenu";
import * as NavigationStyled from "../Navigation.styled";
import * as Styled from "./ShopNavigation.styled";
import SiteSearch, {
  SiteSearchFlexWrapper,
} from "components/organisms/SiteSearch";

export const menuItems = [
  {
    text: "Shop",
    link: "/teaching/shop",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "Blog",
    link: "/teaching/blog",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "Guides",
    link: "/teaching/guide",
    emoji: "✏",
    includeDesktop: true,
  },
  {
    text: "Tools",
    link: "/teaching/tools",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "FAQ",
    link: "/teaching/faq",
    emoji: "",
    includeDesktop: true,
  },
  {
    text: "About",
    link: "/teaching/about",
    emoji: "✏",
    includeDesktop: true,
  },
];

const StyledBadge = styled(Badge)`
  > span {
    background: var(--color-warmAccent);
    font-size: ${xsFont};
    font-family: ${fontFamilyRegular};
    cursor: pointer;
  }
`;

export default function ShopNavigation() {
  const cart = useCartContext();
  const { isOpen, toggleCartOpen } = useCartMetaContext();
  const { colorMode } = React.useContext(ThemeContext);
  const isDark = colorMode === "dark";
  const router = useRouter();
  const numberOfitemsInCart = numberOfCartItems(cart);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  const renderMenuRight = (isMobile: boolean) => {
    return (
      <>
        {!isMobile &&
          menuItems &&
          menuItems
            .filter((menuItem) => menuItem.includeDesktop)
            .map((menuItem) => (
              <NavigationStyled.MenuLink
                key={menuItem.link}
                href={menuItem.link}
                isActive={router.pathname === menuItem.link}
              >
                {menuItem.text}
              </NavigationStyled.MenuLink>
            ))}
        <StyledBadge
          badgeContent={numberOfitemsInCart}
          color="primary"
          overlap="circular"
          onClick={toggleCartOpen}
          style={{ marginRight: isMobile ? "0.9375rem" : "0" }}
        >
          <Button
            icon={<ShoppingCart />}
            colourName="background"
            appearance="solid_borderless"
          />
        </StyledBadge>

        <CartModal isOpen={isOpen} onClose={toggleCartOpen} />
        {!isMobile ? (
          <NavigationStyled.ToggleContainer>
            <DarkModeToggle />
          </NavigationStyled.ToggleContainer>
        ) : null}

        {isMobile ? (
          <Burger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        ) : null}
      </>
    );
  };

  return (
    <NavigationStyled.Header>
      <NavigationStyled.Content>
        {/* DESKTOP */}
        <Styled.DesktopNav>
          <Styled.LeftContent>
            <NavigationStyled.MenuLeft>
              <Link href="/teaching" passHref legacyBehavior>
                <NavigationStyled.HomeLink>
                  <NewNextImage
                    src={isDark ? LogoDark : LogoLight}
                    alt="attheminute"
                    height={50}
                  />
                </NavigationStyled.HomeLink>
              </Link>
            </NavigationStyled.MenuLeft>
          </Styled.LeftContent>
          <Styled.RightContent>
            <Styled.SiteSearchWrapper>
              <SiteSearch />
            </Styled.SiteSearchWrapper>
            <Styled.MenuRight>{renderMenuRight(false)}</Styled.MenuRight>
          </Styled.RightContent>
        </Styled.DesktopNav>

        {/* MOBILE */}
        <NavigationStyled.MobileNav>
          <MobileMenu
            isMenuOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            isTeachingMenu
          />
          <Link href="/teaching" passHref legacyBehavior>
            <NavigationStyled.HomeLink>
              <NewNextImage
                src={isDark ? LogoDark : LogoLight}
                alt="attheminute"
                height={50}
              />
            </NavigationStyled.HomeLink>
          </Link>
          <NavigationStyled.MenuRight>
            {renderMenuRight(true)}
          </NavigationStyled.MenuRight>
        </NavigationStyled.MobileNav>
      </NavigationStyled.Content>
    </NavigationStyled.Header>
  );
}

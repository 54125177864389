import styled from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import {
  PaddedContainer as DefaultPaddedContainer,
  Content as DefaultContent,
} from "components/ui/Layout";
import Card from "components/atoms/Card";

export const PaddedContainer = styled(DefaultPaddedContainer)`
  background: var(--color-lightShades);
  margin-bottom: ${spacing.md};
`;

export const Content = styled(DefaultContent)``;

export const Title = styled.div`
  overflow: auto;
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: ${spacing.sm};

  ${screenSize.minTablet`
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: ${spacing.sm};
  `}

  ${screenSize.minDesktop`
    grid-gap: ${spacing.md};
  `}
`;

export const MainContent = styled.div`
  grid-column: span 1;
  ${screenSize.minTablet`
    grid-column: span 3;
  `}
`;

interface SidebarProps {
  isMobile?: boolean;
}

export const Sidebar = styled.div<SidebarProps>`
  ${(props) => (props.isMobile ? "display: block" : "display: none")};
  grid-column: span 1;

  ${screenSize.minTablet`
    ${(props: SidebarProps) =>
      props.isMobile ? "display: none" : "display: block"};
  `}
`;

export const OurPicks = styled.div`
  grid-column: span 3;
  flex-direction: column;
  > div {
    margin-bottom: ${spacing.sm};
  }
`;

export const SubscribeCard = styled(Card)`
  flex-direction: column;
  padding: ${spacing.md};
`;

export const SmallArticles = styled.div`
  > * {
    margin-bottom: ${spacing.sm};
  }
`;

interface CategoryBadgesProps {
  isMobile?: boolean;
}

export const CategoryBadges = styled.div<CategoryBadgesProps>`
  ${(props: CategoryBadgesProps) =>
    props.isMobile ? "display: flex" : "display: none"};
  flex-wrap: wrap;

  ${screenSize.minTablet`
    ${(props: CategoryBadgesProps) =>
      props.isMobile ? "display: none" : "display: flex"};
  `}

  > a {
    margin-right: ${spacing.xxs};
    margin-bottom: ${spacing.xxs};

    ${screenSize.minTablet`
      margin-right: ${spacing.xxs};
      margin-bottom: ${spacing.xxs};
    `}

    ${screenSize.minDesktop`
      margin-right: ${spacing.xs};
      margin-bottom: ${spacing.xs};
    `}
  }
`;

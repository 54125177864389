import React from "react";
import Modal from "components/ui/Modal";
import { TextH2 } from "styles/typography";
import { createCheckoutSession } from "api/marketplace";
import { ShoppingCart } from "components/ui/Icons";
import { getCartSubTotal, formatPrice } from "utils/storeHelpers";
import { useCartContext, useCartMetaContext } from "components/hocs/CartProvider";
import { colourNames } from "styles/colours";
import { TextP } from "styles/typography";
import CartContent from "./CartContent";
import * as Styled from "./CartContent.styled";
import { useRouter } from "next/router";
import { currencyFromNextJSLocale } from "utils/localisation";

interface CartModalProps {
  isOpen: boolean;
  onClose: () => void;
  dataTestidCloseModal?: string;
}

export default function CartModal(props: CartModalProps) {
  const { isOpen, onClose, dataTestidCloseModal = "close_cart_modal" } = props;
  const cart = useCartContext();
  const { isFetchingPrices } = useCartMetaContext();
  const [isLoading, setLoading] = React.useState(false);

  const { locale } = useRouter();
  const currency = currencyFromNextJSLocale(locale);

  const handleCheckout = async () => {
    setLoading(true);
    try {
      const redirectURL = await createCheckoutSession(cart, currency);
      if (redirectURL) {
        window.location.href = redirectURL;
      }
    } catch (e) {
      // TODO: Logging here.
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      ariaLabel="Sign in and sign up modal"
      renderHeaderContent={() => <TextH2 textAlignCenter>Cart</TextH2>}
      renderFooterContent={() => {
        return (
          <div style={{ textAlign: "center" }}>
            {!cart || cart.length === 0 ? null : (
              <>
                <TextP>
                  {isFetchingPrices ? 'Fetching latest prices...' : (
                    <strong>
                      Total:{" "}
                      {formatPrice(getCartSubTotal(cart, currency), {
                        alwaysShowDecimal: true,
                      })}
                      {" "}{currency}
                    </strong>
                  )}
                </TextP>
                <Styled.CheckoutButton
                  colourName={colourNames.MAIN_BRAND_COLOUR}
                  appearance="square"
                  onClick={() => !isLoading && handleCheckout()}
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <ShoppingCart />
                  &nbsp;Checkout
                </Styled.CheckoutButton>
              </>
            )}
          </div>
        );
      }}
    >
      {!cart || cart.length === 0 ? (
        <TextP textAlignCenter>Your cart is empty!</TextP>
      ) : null}
      <CartContent />
    </Modal>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import Close from '@mui/icons-material/Close';
import Button from "components/ui/Button";
import * as Styled from "./Modal.styled";

export default function Modal(props) {
  const {
    isOpen,
    onClose,
    dataTestIdContent,
    dataTestidCloseModal,
    animate,
    children,
    ariaLabel,
    backgroundColourName,
    renderHeaderContent,
    renderFooterContent,
  } = props;

  const animationProps = useSpring({
    reverse: !isOpen,
    from: { transform: "translateX(100%)", opacity: 0 },
    to: { transform: "translateX(0)", opacity: 1 },
  });
  const AnimatedContent = React.useMemo(
    () => (animate ? animated(Styled.Content) : Styled.Content),
    [animate]
  );

  return (
    <>
      <Styled.GlobalModalOverlayStyles />
      <Styled.ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={ariaLabel}
        overlayClassName={Styled.ModalOverlayStyle.styledComponentId}
      >
        <AnimatedContent
          data-testid={dataTestIdContent}
          style={animationProps}
          backgroundColour={backgroundColourName}
        >
          <Styled.ButtonWrapper>
            <Button
              data-testid={dataTestidCloseModal}
              aria-label="Close Button"
              appearance="solid_borderless"
              colourName="text"
              onClick={onClose}
              icon={<Close />}
            />
          </Styled.ButtonWrapper>
          {renderHeaderContent ? (
            <Styled.HeaderContent>{renderHeaderContent()}</Styled.HeaderContent>
          ) : null}
          <Styled.ChildContent>{children}</Styled.ChildContent>
          {renderFooterContent ? (
            <Styled.FooterContent>{renderFooterContent()}</Styled.FooterContent>
          ) : null}
        </AnimatedContent>
      </Styled.ReactModal>
    </>
  );
}

Modal.defaultProps = {
  dataTestIdContent: "generic-modal-content",
  dataTestidCloseModal: "generic-modal-close",
  animate: false,
  children: null,
  ariaLabel: "",
  backgroundColourName: null,
  renderHeaderContent: undefined,
  renderFooterContent: undefined,
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  animate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dataTestIdContent: PropTypes.string,
  dataTestidCloseModal: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  ariaLabel: PropTypes.string,
  backgroundColourName: PropTypes.string,
  renderHeaderContent: PropTypes.func,
  renderFooterContent: PropTypes.func,
};

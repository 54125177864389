import styled, { createGlobalStyle } from "styled-components";
import screenSize from "styles/mediaQueries";
import spacing from "styles/spacing";
import layout from "styles/layout";
import Modal from "react-modal";
// import Button from "components/ui/Button";

// Keep this empty
export const ModalOverlayStyle = styled.div``;

// Overlay
export const GlobalModalOverlayStyles = createGlobalStyle`
  ${ModalOverlayStyle} {
    // Your custom styling here
    z-index: 19;
    background-color: var(--color-modalOverlay);
    display: flex;
    position: fixed;

    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    -webkit-overflow-scrolling: touch;
    top: calc(0px + env(safe-area-inset-top));
    overflow-y: scroll;
  }
`;

// Content
export const ReactModal = styled(Modal)`
  outline: none;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  max-width: ${layout.blogMaxWidth};
  display: flex;
  justify-content: center;
  align-items: center;

  ${screenSize.minTablet`
    height: auto;
    padding: ${spacing.sm} 0;
  `}

  ${screenSize.minDesktop`
    padding: ${spacing.md} 0;
  `}
`;

export const HeaderContent = styled.header`
  display: flex;
  justify-content: center;
  height: fit-content;
  align-items: center;
  min-height: ${spacing.lg};
`;

export const ChildContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const FooterContent = styled.footer`
  align-self: flex-end;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  background: ${(props) =>
    props.backgroundColour
      ? `var(--color-${props.backgroundColour})`
      : "var(--color-lightShades)"};

  padding: ${spacing.sm};

  ${screenSize.minTablet`
    padding: ${spacing.sm};
    border-radius: 6px;
    height: auto;
  `}

  ${screenSize.minDesktop`
    padding: ${spacing.sm};
  `}
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing.xxs};
  right: ${spacing.xxs};
  z-index: 100000;
`;

import styled, { css } from "styled-components";
import screenSize from "styles/mediaQueries";

export interface InputStyleProps {
  $stackedLayout?: boolean;
  disabled?: boolean;
}

export const inputStyles = css<InputStyleProps>`
  border: none;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid var(--color-inputOutline);

  background: ${(props) =>
    props.disabled ? "var(--color-inputDisabled)" : "var(--color-background)"};

  ${screenSize.minTablet`
    width: auto;
    ${(props: { $stackedLayout?: boolean }) =>
      props.$stackedLayout
        ? `width: 100%`
        : `
        margin-bottom: 0;
      `}
  `}
`;

export const Input = styled.input<{ $stackedLayout?: boolean }>`
  ${inputStyles}
`;
